module.exports = {
    // Sometimes, XR collaborates with other organisations to provide arrestee support.
    // When this happens, we need to display different privacy and consent notices.
    // Also, arrestees can select which organisation should provide support on the release form.
    // In the backoffice system, it is possible to filter release reports by the support organisation
    // selected.
    //
    // If only one support organisation is selected, these options are not visible.

    // The legalNotices array contains multiple groups of privacy and consent notices.
    // Choose which one to use by setting isActive=true.
    legalNotices: [
        {
            id: "XROnly",
            privacyNotice: "This service is provided by XR (UK). By using this service you agree to the <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"https://extinctionrebellion.uk/privacy-policy/\">XR (UK) Privacy Notice</a>.",
            witnessConsentNotice: "Do you agree that your personal data may be processed by XR UK in accordance with the privacy notice linked below, and, if you have provided your email address, that they may contact you about this arrest report.",
            arresteeConsentNotice: "Do you agree that XR UK may process your personal data in accordance with their privacy notice (linked below) and may contact you by:",
            isActive: true
        },
        {
            id: "G7",
            privacyNotice: "This service is provided by XR (UK) and Green and Black Cross. By using this service you agree to the <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"https://greenandblackcross.org/privacy-cookies/\">Green and Black Cross Privacy Notice</a>, the <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"https://extinctionrebellion.uk/privacy-policy/\">XR (UK) Privacy Notice</a>, and the <a target=\"_blank\" rel=\"noopener noreferrer\" href=\"http://www.extinctionrebellion.uk/arrestee-support-privacy-notice\">XR (UK) Arrestee Support Privacy Notice</a>.",
            witnessConsentNotice: "I agree that my personal data may be processed by Green and Black Cross/XR UK in accordance with their privacy notices linked below, and, if I have provided my email address, they may contact me about this arrest report.",
            arresteeConsentNotice: "I agree that Green and Black Cross and XR UK may process my personal data in accordance with their respective privacy notices (linked below) and may contact me by:",
            isActive: false
        }
    ],
    supportOrganisations: [
        {
            name: "Extinction Rebellion UK",
            shortName: "XR",
            information: "If you are a member of XR, post-arrest support will be provided by XR Arrestee and Legal Support",
            isActive: true
        },
        {
            name: "Green and Black Cross",
            shortName: "GBC",
            information: "If you are not part of XR, and were arrested in England or Wales, please select this option",
            isActive: false
        },
        {
            name: "Scottish Community & Activist Legal Project",
            shortName: "SCALP",
            information: "If you are not part of XR, and were arrested in Scotland, please select this option",
            isActive: false
        }
    ],
    phoneNumber: "07749 335574",
    // The default time that arrest and release reports are visible in the backoffice system
    reportVisibilityTimeoutMilliSeconds: 48 * 60 * 60 * 1000
}