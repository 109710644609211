<template>
  <div>
    <b-navbar toggleable="md" type="dark" variant="primary">
      <b-nav-toggle target="nav_collapse"></b-nav-toggle>

      <b-navbar-brand :to="{ name: 'Arrests'}">
        <span>ArrestWatch</span>
      </b-navbar-brand>

      <b-collapse is-nav id="nav_collapse">
        <b-navbar-nav>
          <b-nav-item data-toggle="tooltip" title="Arrest reports" :to="{ name: 'Arrests' }">Arrests</b-nav-item>
          <b-nav-item data-toggle="tooltip" title="Release reports"  :to="{ name: 'Releases' }">Releases</b-nav-item>
          <!-- There's been a suggestion that the Stats tab isn't helpful -->
          <!-- <b-nav-item :to="{ name: 'Stats' }">Stats</b-nav-item> -->
          <b-nav-item data-toggle="tooltip" title="Track legal observer availability"  :to="{ name: 'LegalObserverLog' }">Legal observers</b-nav-item>
          <b-nav-item data-toggle="tooltip" title="Custody calls and miscellaneous information"  :to="{ name: 'Calls' }">Calls</b-nav-item>
          <b-nav-item data-toggle="tooltip" title="Show ArrestWatch users"  :to="{ name: 'Users' }">Users</b-nav-item>
          <b-nav-form>
            <label for="filter-datetime" @click="openDateTimePicker">View data from:&nbsp;</label><datetime id="filter-datetime" ref="globalFilterDateTime" type="datetime" input-class="form-control" v-model="filterDateStart"></datetime>
          </b-nav-form>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item right :to="{ name: 'Login' }" v-if="!$store.state.userId">Login</b-nav-item>
          <b-nav-item right  data-toggle="tooltip" title="Logout or change your password" :to="{ name: 'Login' }" v-if="$store.state.userId">Logged in as {{ $store.state.nickname }}</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-container fluid>
      <b-alert :show="connectionBroken" variant="warning">Connection lost with the server. Refresh to re-establish connection.</b-alert>
      <router-view class="mt-4"></router-view>
    </b-container>
    <footer class="mt-3 text-center">
      <div class="container">
        <span class="text-muted">
          <small>
            For help, contact: {{phoneNumber}}
          </small>
        </span>
      </div>
    </footer>
  </div>
</template>

<script>
const config = require('../../config/site_config.js');

export default {
  name: 'app',
  data: () => ({
    io: null,
    isConnected: null,
    isConnecting: false,
    phoneNumber: config.phoneNumber
  }),
  watch: {
    '$store.state.userId': 'connectState'
  },
  created () {
    this.connectState()
  },
  computed: {
    filterDateStart: {
      get () {
        return this.$store.state.filterDateStart.toISOString()
      },
      set (newValue) {
        this.$store.commit('setFilterDateStart', new Date(newValue))
      }
    },
    connectionBroken: function () {
        return (this.isConnected === false) && (this.isConnecting === false) && (this.$store.state.userId)
    }
  },
  methods: {
    openDateTimePicker() {
      this.$refs.globalFilterDateTime.isOpen = true;
    },
    connectState () {
      this.isConnecting = true
      if (!this.$io.socket.isConnected() && !this.$io.socket.mightBeAboutToAutoConnect()) {
        this.$io.socket.reconnect()
      }
      this.$io.socket.on('connect', () => {
        this.isConnected = true
        this.isConnecting = false
      })
      this.$io.socket.on('disconnect', () => {
        this.isConnected = false
        this.isConnecting = false
      })
      this.$io.socket.get('/api/v1/user/current', (resData, jwRes) => {
        if (jwRes.statusCode == 401) {
          this.$router.push('login')
          this.$io.socket.disconnect()
        } else if (jwRes.statusCode == 200) {
          this.$store.commit('logIn', resData)

          var threshold = Date.now() - config.reportVisibilityTimeoutMilliSeconds
          this.$io.socket.get('/api/v1/report', {limit: 10000, where: {updatedAt: {">": threshold}}}, (resData, jwRes) => {
            if (jwRes.statusCode != 200) {
              return
            }
            this.$store.commit('setReports', resData)
            this.$io.socket.on('report', (e) => {
                if (e.verb === 'created') {
                  this.$store.commit('addReport', e.data)
                } else if (e.verb === 'updated') {
                  this.$store.commit('updateReport', e.data)
                }
            })
          })

          this.$io.socket.get('/api/v1/release', {limit: 10000, where: {updatedAt: {">": threshold}}}, (resData, jwRes) => {
            if (jwRes.statusCode != 200) {
              return
            }
            this.$store.commit('setReleases', resData)
            this.$io.socket.on('release', (e) => {
              if (e.verb === 'created') {
                this.$store.commit('addRelease', e.data)
              } else if (e.verb === 'updated') {
                  this.$store.commit('updateRelease', e.data)
                }
            })
          })
          this.$io.socket.get('/api/v1/legalobserverlog', {limit: 10000}, (resData, jwRes) => {
            if (jwRes.statusCode != 200) {
              return
            }
            this.$store.commit('setLegalObserverLogs', resData)
            this.$io.socket.on('legalobserverlog', (e) => {
              if (e.verb === 'created') {
                this.$store.commit('addLegalObserverLog', e.data)
              } else if (e.verb === 'updated') {
                  this.$store.commit('updateLegalObserverLog', e.data)
              }
            })
          })
          this.$io.socket.get('/api/v1/calllog', {limit: 10000}, (resData, jwRes) => {
            if (jwRes.statusCode != 200) {
              return
            }
            this.$store.commit('setCallLogs', resData)
            this.$io.socket.on('calllog', (e) => {
              if (e.verb === 'created') {
                this.$store.commit('addCallLog', e.data)
              } else if (e.verb === 'updated') {
                this.$store.commit('updateCallLog', e.data)
              }
            })
          })
        }
      })
    }
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
