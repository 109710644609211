import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
const routes = [
    {
      path: '/',
      name: 'Arrests',
      component: resolve => {
        require(['./components/Arrests.vue'], resolve)
      }
    },
    {
      path: '/stats',
      name: 'Stats',
      component: resolve => {
        require(['./components/Stats.vue'], resolve)
      }
    },
    {
      path: '/releases',
      name: 'Releases',
      component: resolve => {
        require(['./components/Releases.vue'], resolve)
      }
    },
    {
      path: '/calls',
      name: 'Calls',
      component: resolve => {
        require(['./components/Calls.vue'], resolve)
      }
    },
    {
      path: '/legalobserverlog',
      name: 'LegalObserverLog',
      component: resolve => {
        require(['./components/LegalObserverLog.vue'], resolve)
      }
    },
    {
      path: '/users',
      name: 'Users',
      component: resolve => {
        require(['./components/Users.vue'], resolve)
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: resolve => {
        require(['./components/Login.vue'], resolve)
      }
    },
];


const router = new Router({
    mode: 'history',
    linkExactActiveClass: "active",
    routes
});

export default router;

router.beforeEach(async (to, from, next) => {

  if (to.name === 'Login') {
    next();
    return;
  }

  if (router.app.$store.state.userId == null) {
      // User ID isn't set - try to get it from the server (using our session cookie)
      const response = await fetch('/api/v1/user/current');
      if (response.status == 200) {
        router.app.$store.commit('logIn', await response.json());
        next();
      } else {
          next('Login');
      }
  } else {
    // User ID is set, so we're logged in
    next();
  }
});
