import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

var filterDateStart = new Date(Date.now() - 1000*60*60*24)
filterDateStart.setMinutes(0, 0, 0)

const state = {
  userId: null,
  username: null,
  nickname: null,
  filterDateStart,
  reports: [],
  releases: [],
  legalObserverLogs: [],
  callLogs: [],
  sortStates: {
    // Set default sorting for each tab here
    arrests: {column: "createdAt", descending: true },
    releases: {column: "createdAt", descending: true },
    legalObservers: {column: "createdAt", descending: true },
    calls: {column: "callTime", descending: true },
    users: {column: "createdAt", descending: true }
  }
};

const mutations = {
  setReport(state, x) {
    for (var i in state.reports) {
      if (state.reports[i].id == x.id) {
        Object.assign(state.reports[i], x)
        return
      }
    }
    state.reports.push(x)
  },
  setReports(state, x) {
    state.reports = x
  },
  addReport(state, x) {
    state.reports.push(x)
  },
  updateReport(state, x) {
    updateItem(state.reports, x)
  },
  setRelease(state, x) {
    for (var i in state.releases) {
      if (state.releases[i].id == x.id) {
        Object.assign(state.releases[i], x)
        return
      }
    }
    state.releases.push(x)
  },
  setReleases(state, x) {
    state.releases = x
  },
  addRelease(state, x) {
    state.releases.push(x)
  },
  updateRelease(state, x) {
    updateItem(state.releases, x)
  },  
  setLegalObserverLog(state, x) {
    for (var i in state.legalObserverLogs) {
      if (state.legalObserverLogs[i].id == x.id) {
        Object.assign(state.legalObserverLogs[i], x)
        return
      }
    }
    state.legalObserverLogs.push(x)
  },
  setLegalObserverLogs(state, x) {
    state.legalObserverLogs = x
  },
  addLegalObserverLog(state, x) {
    state.legalObserverLogs.push(x)
  },
  updateLegalObserverLog(state, x) {
    updateItem(state.legalObserverLogs, x)
  },
  setCallLog(state, x) {
    for (var i in state.callLogs) {
      if (state.callLogs[i].id == x.id) {
        Object.assign(state.callLogs[i], x)
        return
      }
    }
    state.callLogs.push(x)
  },
  setCallLogs(state, x) {
    state.callLogs = x
  },
  setSortState(state, {tab, column, descending}) {
    state.sortStates[tab] = {column: column, descending: descending}
  },
  addCallLog(state, x) {
    state.callLogs.push(x)
  },
  updateCallLog(state, x) {
    updateItem(state.callLogs, x)
  },
  logIn(state, user) {
    state.userId = user.id
    state.username = user.username
    state.nickname = user.nickname || user.username
    localStorage.setItem('user', JSON.stringify(user))
  },
  logOut(state) {
    state.userId = null
    state.username = null
    state.nickname = null
    localStorage.removeItem('user')
  },
  setFilterDateStart(state, date) {
    state.filterDateStart = date
  }
};

const getters = {
  filteredReports(state) {
    return state.reports.filter(report => report.updatedAt > state.filterDateStart)
  },
  filteredReleases(state) {
    return state.releases.filter(release => release.updatedAt > state.filterDateStart)
  }
};

const updateItem = function(array, newItem) {
  const index = array.findIndex(i => i.id === newItem.id);
  if (index < 0) {
    array.push(newItem)
  } else {
    array.splice(index, 1, newItem)
  }
}

const store = new Vuex.Store({
    state: state,
    mutations: mutations,
    getters: getters
  });

export default store;
